window.addEventListener('load', function () {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    const markup = `
    <div class="popup-container">
        <p class="popup-message">
            Die Inhalte dieser Seite werden nicht für den Internet Explorer optimiert. Bitte benutzen Sie einen anderen Browser, wie z. B. Edge, Safari oder Firefox.
            <br>
            <br>
            <a href="javascript:;" >URL in Zwischenablage kopieren </a>
            <span id="copyToast">&nbsp;&nbsp;Die URL wurde erfolgreich kopiert ✓</span>
        </p>
        <div class="close-button">
            <span class="close-icon">⨉</span>
        </div>
    </div>
    `;

    const styles = `
    .ie-popup {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 100;
        background: rgba(0,0,0,.7);
        transition: opacity .5s;
        opacity: 1;
    }

    .popup-body {
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
    }

    .popup-container {
        display: flex;
        flex-direction: row;
        margin: 25%;
        background-color: #fff;
        border: 3px solid #006B2D;
    }

    .popup-message {
        flex-grow: 1;
        padding: 48px 24px 48px 48px;
    }

    .popup-message > a {
        color: #006B2D;
        font-weight: 700;
        margin-top: 15px;
    }
    
    .popup-message > span {
        display: none;
        color: #006B2D;
        font-weight: 700;
        margin-top: 15px;
    }
    
    .close-button .close-icon {
        flex-grow: 0;
        min-width: 24px;
        font-size: 36px;
        color: #9d9d9c;
        margin-top: 24px;
        margin-right: 24px;
        cursor: pointer;
    }
    `;

    // append popup and styles
    if (isIE11) {
        // append styles
        var head = document.head || document.getElementsByTagName('head')[0];
        var style = document.createElement('style');

        head.appendChild(style);

        if (style.styleSheet) {
            style.styleSheet.cssText = styles;
        } else {
            style.appendChild(document.createTextNode(styles));
        }

        // append popup
        var body = document.body;
        var popup = document.createElement('div')
        popup.classList.add('ie-popup');
        popup.classList.add('popup-body')
        popup.innerHTML = markup.trim();

        body.appendChild(popup);

        // close popup
        var close = document.querySelector('.close-button .close-icon');
        close.addEventListener('click', e => {
            popup.style.visibility = 'hidden';
        });

        // copy to clipboard
        var copy = document.querySelector('.popup-message a');
        copy.addEventListener('click', e => {
            copyTextToClipboard(window.location.href);
        });
    }

    function copyTextToClipboard(text) {
        var copyBtn = document.querySelector('#copyToast');
        copyBtn.style.display = 'inline';
        window.clipboardData.setData('Text', text);
    }
});